<template>
	<div class="main-pdf">
		<div class="main-content">
			<pdf ref="pdf" 
				:src="url" 
				:page="pageNum"
				:rotate="pageRotate"  
				@progress="loadedRatio = $event"
				@page-loaded="pageLoaded($event)" 
				@num-pages="pageTotalNum=$event" 
				@error="pdfError($event)" 
				@link-clicked="page = $event">
			</pdf>
			<div class="tools">
				<div @click.stop="prePage" class="mr10"> 上一页</div> 
				<div @click.stop="nextPage" class="mr10"> 下一页</div>
				<div class="page">{{pageNum}}/{{pageTotalNum}} </div>
				<div @click.stop="clock" class="mr10"> 顺时针</div>
				<div @click.stop="counterClock" class="mr10"> 逆时针</div>
			</div>
		</div>
	</div>
</template>

<script>
import pdf from 'vue-pdf'
import axios from 'axios'
export default{
	components:{pdf},
	data() {
		return {
			url:'',
			pageNum: 1,
			pageTotalNum: 1,
			pageRotate: 0,
			// 加载进度
			loadedRatio: 0,
			curPageNum: 0,
		}
	},
	created(){
		this.$store.commit('changClick',1)
		console.log(this.$route.query.fileUrl,'asdadsadasdsa')
		this.pdfDlg(this.$route.query.fileUrl)
	},
	methods:{
		pdfDlg(url){
			axios({
			    method: 'GET',
			    url: url, // 后台接口
			    paramsSerializer: function(params) {
					return qs.stringify(params, { arrayFormat: 'brackets' })
			    },
			    headers: {
			        'Content-Type': 'application/vnd.openxmlformats- officedocument.spreadsheetml.sheet'
			    },
			    responseType: 'blob'
			}).then(response => {
			    this.url = this.getObjectURL(response.data)
			}).catch(error => { 
				this.$message.error('' + error) 
			})
		},
		/**处理文件流**/ 
		getObjectURL(file) {
		    let url = null
		    if (window.createObjectURL !== undefined) { // basic
				url = window.createObjectURL(file)
		    } else if (window.webkitURL !== undefined) { // webkit or chrome
		        try {
					url = window.webkitURL.createObjectURL(file)
		        } catch (error) {
					console.log(error)
		        }
		    } else if (window.URL !== undefined) { // mozilla(firefox)
		        try {
					url = window.URL.createObjectURL(file)
		        } catch (error) {
					console.log(error)
		        }
		    }
		    return url
		},
		/**上一页函数，**/ 
		prePage() {
			var page = this.pageNum
			page = page > 1 ? page - 1 : this.pageTotalNum
			this.pageNum = page
		},
		/**下一页函数**/ 
		nextPage() {
			var page = this.pageNum
			page = page < this.pageTotalNum ? page + 1 : 1
			this.pageNum = page
		},
		/**页面顺时针翻转90度。**/ 
		clock() {
			this.pageRotate += 90
		},
		/**页面逆时针翻转90度。**/ 
		counterClock() {
			this.pageRotate -= 90
		},
		/**页面加载回调函数，其中e为当前页数**/
		pageLoaded(e) {
			this.curPageNum = e
		},
		/**其他的一些回调函数。**/ 
		pdfError(error) {
			console.error(error)
		},
	}
}
</script>

<style scoped lang="scss">
.main-pdf{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.main-content{
		width: 1200px;
	}
}
.tools{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	margin-bottom: 40px;
	.mr10{
		width: 100px;
		height: 100%;
		line-height: 40px;
		cursor: pointer;
	}
	.page{
		width: 100px;
		height: 100%;
		line-height: 40px;
	}
}
</style>
